import {
  GET_KEYMANS_REQUEST,
  GET_KEYMANS_SUCCESS,
  GET_KEYMANS_FAILURE,
  GET_KEYMANS_RELEVANT_REQUEST,
  GET_KEYMANS_RELEVANT_SUCCESS,
  GET_KEYMANS_RELEVANT_FAILURE,
} from "../../constants/action-types"

const { OrderedMap } = require("immutable")

const initialState = {
  keymans: OrderedMap({}),
  isLoadingKeymans: false,
  honorificRelevantKeyman: null,
  departmentRelevantKeyman: null,
  relevantKeymans: OrderedMap({}),
  pageInfo_keyman: null,
  requestPage: 1,
  currentKeyword: "",
  isLoadingRelevantKeymans: false,
  isInitialized: false,

}

export default (state = initialState, action) => {
  let list, honorificRelevantKeyman, departmentRelevantKeyman;

  switch (action.type) {
    case GET_KEYMANS_REQUEST:
      return {
        ...state,
        isLoadingKeymans: true,
        isInitialized: true,
        requestPage: action.payload.page,
        currentKeyword: action.payload.keyword,
        keymans: action.payload.overwrite ? OrderedMap({}) : state.keymans,
        pageInfo_keyman: action.payload.overwrite
          ? null
          : state.pageInfo_keyman,
      }
    case GET_KEYMANS_SUCCESS:
      if (
        action.payload.pageInfo &&
        action.payload.pageInfo.current_page.toString() ===
          state.requestPage.toString() &&
        action.payload.keyword === state.currentKeyword
      ) {
        list = OrderedMap({})

        action.payload.keymans.forEach(item => {
          list = list.set(item.id, item)
        })

        return {
          ...state,
          isLoadingKeymans: false,
          keymans: list,
          pageInfo_keyman: action.payload.pageInfo,
        }
      } else {
        return state
      }
    case GET_KEYMANS_FAILURE:
      return {
        ...state,
        isLoadingKeymans: false,
      }
    case GET_KEYMANS_RELEVANT_REQUEST:
      return {
        ...state,
        isLoadingRelevantKeymans: true,
        honorificRelevantKeyman: null,
        departmentRelevantKeyman: null,
        relevantKeymans: OrderedMap({}),
      }
    case GET_KEYMANS_RELEVANT_SUCCESS:
      list = OrderedMap({})
      if (action.payload.data && action.payload.data.honorificRelevantKeyman) {
        honorificRelevantKeyman = action.payload.data.honorificRelevantKeyman;
      }
      if (action.payload.data && action.payload.data.departmentRelevantKeyman) {
        departmentRelevantKeyman = action.payload.data.departmentRelevantKeyman;
      }
      if (action.payload.data && action.payload.data.relevantKeymans) {
        action.payload.data.relevantKeymans.forEach(item => {
          list = list.set(item.id, item)
        })
      }

      return {
        ...state,
        isLoadingRelevantKeymans: false,
        honorificRelevantKeyman: honorificRelevantKeyman,
        departmentRelevantKeyman: departmentRelevantKeyman,
        relevantKeymans: list,
      }
    case GET_KEYMANS_RELEVANT_FAILURE:
      return {
        ...state,
        isLoadingRelevantKeymans: false,
      }
    default:
      return state
  }
}
