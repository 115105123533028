import {
  GET_CORPORATIONS_REQUEST,
  GET_CORPORATIONS_SUCCESS,
  GET_CORPORATIONS_FAILURE,
  POST_CORPORATION_INTENT_CSV_REQUEST,
  POST_CORPORATION_INTENT_CSV_SUCCESS,
  POST_CORPORATION_INTENT_CSV_FAILURE,
} from "../../constants/action-types"

const { OrderedMap } = require("immutable")

const initialState = {
  corporations: OrderedMap({}),
  isLoadingCorps: false,
  pageInfo_corp: null,
  requestPage: 1,
  isInitialized: false,
  isLoadingCorporationIntentDownload: false,
  corporationIntentDownloadSuccessMessage: "",
  corporationIntentDownloadErrorMessage: "",
}

export default (state = initialState, action) => {
  let list

  switch (action.type) {
    case GET_CORPORATIONS_REQUEST:
      return {
        ...state,
        isLoadingCorps: true,
        corporations: action.payload.overwrite
          ? OrderedMap({})
          : state.corporations,
        pageInfo_corp: action.payload.overwrite ? null : state.pageInfo_corp,
        requestPage: action.payload.page,
        isInitialized: true,
      }
    case GET_CORPORATIONS_SUCCESS:
      if (
        action.payload.pageInfo &&
        action.payload.pageInfo.current_page.toString() ===
          state.requestPage.toString()
      ) {
        list = OrderedMap({})

        action.payload.corporations.forEach(item => {
          list = list.set(item.id, item)
        })

        return {
          ...state,
          isLoadingCorps: false,
          corporations: list,
          pageInfo_corp: action.payload.pageInfo,
        }
      } else {
        return state
      }
    case GET_CORPORATIONS_FAILURE:
      return {
        ...state,
        isLoadingCorps: false,
      }
    case POST_CORPORATION_INTENT_CSV_REQUEST:
      return {
        ...state,
        isLoadingCorporationIntentDownload: true,
        corporationIntentDownloadSuccessMessage: "",
        corporationIntentDownloadErrorMessage: "",
      }
    case POST_CORPORATION_INTENT_CSV_SUCCESS:
      return {
        ...state,
        isLoadingCorporationIntentDownload: false,
        corporationIntentDownloadSuccessMessage: action.payload.data.message,
      }
    case POST_CORPORATION_INTENT_CSV_FAILURE:
      return {
        ...state,
        isLoadingCorporationIntentDownload: false,
        corporationIntentDownloadErrorMessage: action.payload.error.response.data.message["email"][0],
      }
    default:
      return state
  }
}
