import { createStore, combineReducers, applyMiddleware } from "redux"
import logger from "redux-logger"
import thunk from "redux-thunk"
import * as reducers from "./reducers/index"

export default () => {
  if (process.env.GATSBY_ACTIVE_ENV === 'production') {
    // 本番ではreduxのログはconsoleに出力しない
    return createStore(combineReducers(reducers), applyMiddleware(thunk))
  } else {
    return createStore(combineReducers(reducers), applyMiddleware(logger, thunk))
  }
}
