import {
  GET_KEYMANS_COUNT_REQUEST,
  GET_KEYMANS_COUNT_SUCCESS,
  GET_KEYMANS_COUNT_FAILURE,
  GET_KEYMANS_INDUSTRIES_SUCCESS,
  GET_RANK_JSIC_L_REQUEST,
  GET_RANK_JSIC_L_SUCCESS,
  GET_RANK_JSIC_L_FAILURE,
} from "../../constants/action-types"
import {
  INDUSTRIES,
  INDUSTRIES_PER_PAGE,
  KEYMAN_RANK_TYPE,
} from "../../constants/constants"

const { OrderedMap } = require("immutable")

let all_industries = OrderedMap({}),
  industries = OrderedMap({})
INDUSTRIES.forEach((item, i) => {
  all_industries = all_industries.set(item.value, item)
})

INDUSTRIES.slice(0, INDUSTRIES_PER_PAGE).forEach((item, i) => {
  industries = industries.set(item.value, item)
})

const initialState = {
  allIndustries: all_industries,
  industries: industries,
  isLoadingIndustries: false,
  pageInfo_industry: {
    current_page: 1,
    last_page: Math.ceil(INDUSTRIES.length / INDUSTRIES_PER_PAGE),
    per_page: INDUSTRIES_PER_PAGE,
    total: INDUSTRIES.length,
  },
  error: null,
  isLoadingIndustryRank: false,
  letterRankStats: null,
  reactionRankStats: null,
  shoudanRankStats: null,
}

export default (state = initialState, action) => {
  let list, industries, start, end, totalCount

  state = {
    ...state,
    error: null,
  }

  switch (action.type) {
    case GET_KEYMANS_COUNT_REQUEST:
      return {
        ...state,
        isLoadingIndustries: true,
      }
    case GET_KEYMANS_COUNT_SUCCESS:
      list = OrderedMap({})
      industries = OrderedMap({})
      start =
        (state.pageInfo_industry.current_page - 1) *
        state.pageInfo_industry.per_page
      end =
        state.pageInfo_industry.current_page * state.pageInfo_industry.per_page

      INDUSTRIES.forEach((item, i) => {
        if (action.payload.data["jsic_l"][item.value] !== 0 && action.payload.data["jsic_l"][item.value] !== undefined) {
          list = list.set(item.value, {
            ...item,
            count: action.payload.data["jsic_l"][item.value],
          })
        }
      })

      list.slice(start, end).forEach((item, i) => {
        industries = industries.set(item.value, {
          ...item,
          count: action.payload.data["jsic_l"][item.value],
        })
      })

      return {
        ...state,
        isLoadingIndustries: false,
        allIndustries: list,
        industries: industries,
        pageInfo_industry: {
          current_page: 1,
          last_page: Math.ceil(list.size / INDUSTRIES_PER_PAGE),
          per_page: INDUSTRIES_PER_PAGE,
          total: list.size,
        },
      }
    case GET_KEYMANS_COUNT_FAILURE:
      return {
        ...state,
        isLoadingIndustries: false,
        error: action.payload.error,
      }
    case GET_KEYMANS_INDUSTRIES_SUCCESS:
      start = (action.payload.page - 1) * action.payload.per_page
      end = action.payload.page * action.payload.per_page

      list = OrderedMap({})

      totalCount = state.allIndustries.size;
      if (state.allIndustries.size === INDUSTRIES.length){
        INDUSTRIES.slice(start, end).forEach((item, i) => {
          list = list.set(item.value, state.allIndustries.get(item.value))
        })
      } else {
        state.allIndustries.slice(start, end).forEach((item, i) => {
          list = list.set(item.value, state.allIndustries.get(item.value))
        })
      }

      return {
        ...state,
        industries: list,
        pageInfo_industry: {
          current_page: action.payload.page,
          last_page: Math.ceil(totalCount / action.payload.per_page),
          per_page: action.payload.per_page,
          total: totalCount,
        },
      }
    case GET_RANK_JSIC_L_REQUEST:
      return {
        ...state,
        isLoadingIndustryRank: true,
      }
    case GET_RANK_JSIC_L_SUCCESS:
      return {
        ...state,
        isLoadingIndustryRank: false,
        letterRankStats: action.payload.data[KEYMAN_RANK_TYPE.LETTER].map(
          item => {
            return { ...item, name: item.jsic_l, key: item.jsic_l }
          }
        ),
        reactionRankStats: action.payload.data[KEYMAN_RANK_TYPE.REACTION].map(
          item => {
            return { ...item, name: item.jsic_l, key: item.jsic_l }
          }
        ),
        shoudanRankStats: action.payload.data[KEYMAN_RANK_TYPE.SHOUDAN].map(
          item => {
            return { ...item, name: item.jsic_l, key: item.jsic_l }
          }
        ),
      }
    case GET_RANK_JSIC_L_FAILURE:
      return {
        ...state,
        isLoadingIndustryRank: false,
        error: action.payload.error,
      }
    default:
      return state
  }
}
