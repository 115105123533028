import {
  GET_EXECUTIVE_COUNT_REQUEST,
  GET_EXECUTIVE_COUNT_SUCCESS,
  GET_EXECUTIVE_COUNT_FAILURE,
} from "../../constants/action-types"

const { OrderedMap } = require("immutable")

const initialState = {
  executive: OrderedMap({}),
}

export default (state = initialState, action) => {
  state = {
    ...state,
    error: null,
  }

  switch (action.type) {
    case GET_EXECUTIVE_COUNT_REQUEST:
      return {
        ...state,
        isLoadingKeymans: true,
        executiveCorporationTotal: null,
        executiveTotal: null,
      }
    case GET_EXECUTIVE_COUNT_SUCCESS:
      return {
        ...state,
        isLoadingKeymans: true,
        executiveCorporationTotal: action.payload.data.executive_corporation_total,
        executiveTotal: action.payload.data.executive_total,
      }
    case GET_EXECUTIVE_COUNT_FAILURE:
      return {
        ...state,
        isLoadingKeymans: false,
        executiveCorporationTotal: null,
        executiveTotal: null,
      }
    default:
      return state
  }
}