import {
  GET_KEYMANS_YESTERDAY_REQUEST,
  GET_KEYMANS_YESTERDAY_SUCCESS,
  GET_KEYMANS_YESTERDAY_FAILURE,
  GET_KEYMANS_TOTAL_SUCCESS,
} from "../../constants/action-types"

const { OrderedMap } = require("immutable")

const initialState = {
  keymansTotal: " - ",
  isLoadingUpdateKeymans: false,
  corporationsTotal: " - ",
  keymanCountYstd: " - ",
  updateKeymans: OrderedMap({}),
}

export default (state = initialState, action) => {
  let list

  switch (action.type) {
    case GET_KEYMANS_YESTERDAY_REQUEST:
      return {
        ...state,
        isLoadingUpdateKeymans: true,
        updateKeymans: action.payload.overwrite ? OrderedMap({}) : state.updateKeymans,
      }
    case GET_KEYMANS_YESTERDAY_SUCCESS:
      list = OrderedMap({})

      action.payload.updateKeymans.forEach(item => {
        list = list.set(item.id, item)
      })
      return {
        ...state,
        isLoadingUpdateKeymans: false,
        updateKeymans: list,
        keymanCountYstd: action.payload.count,
      }
    case GET_KEYMANS_YESTERDAY_FAILURE:
      return {
        ...state,
        isLoadingUpdateKeymans: false,
      }
    case GET_KEYMANS_TOTAL_SUCCESS:
      return {
        ...state,
        corporationsTotal: action.payload.corporationsTotal,
        keymansTotal: action.payload.keymansTotal,
      }
    default:
      return state
  }
}
