import {
  GET_KEYMANS_COUNT_REQUEST,
  GET_KEYMANS_COUNT_SUCCESS,
  GET_KEYMANS_COUNT_FAILURE,
  COUNT_FOR_CUSTOM_PAGES_REQUEST,
  COUNT_FOR_CUSTOM_PAGES_SUCCESS,
  COUNT_FOR_CUSTOM_PAGES_FAILURE,
  GET_KEYMANS_POSITIONS_SUCCESS,
} from "../../constants/action-types"
import { HONORIFICS, POSITIONS_PER_PAGE } from "../../constants/constants"

const { OrderedMap } = require("immutable")

let all_positions = OrderedMap({}),
  positions = OrderedMap({})
HONORIFICS.forEach((item, i) => {
  all_positions = all_positions.set(item.value, item)
})

HONORIFICS.slice(0, POSITIONS_PER_PAGE).forEach((item, i) => {
  positions = positions.set(item.value, item)
})

const initialState = {
  allPositions: all_positions,
  positions: positions,
  isLoadingPosition: false,
  pageInfo_position: {
    current_page: 1,
    last_page: Math.ceil(HONORIFICS.length / POSITIONS_PER_PAGE),
    per_page: POSITIONS_PER_PAGE,
    total: HONORIFICS.length,
  },
  error: null,
  positionCount: ' - ',
}

export default (state = initialState, action) => {
  let list, positions, start, end, totalCount

  state = {
    ...state,
    error: null,
  }

  switch (action.type) {
    case GET_KEYMANS_COUNT_REQUEST:
      return {
        ...state,
        isLoadingPosition: true,
      }
    case GET_KEYMANS_COUNT_SUCCESS:
      list = OrderedMap({})
      positions = OrderedMap({})
      start =
        (state.pageInfo_position.current_page - 1) *
        state.pageInfo_position.per_page
      end =
        state.pageInfo_position.current_page * state.pageInfo_position.per_page

      HONORIFICS.forEach((item, i) => {
        list = list.set(item.value, {
          ...item,
          count: action.payload.data["honorific"][item.value],
        })
      })

      HONORIFICS.slice(start, end).forEach((item, i) => {
        positions = positions.set(item.value, {
          ...item,
          count: action.payload.data["honorific"][item.value],
        })
      })

      return {
        ...state,
        isLoadingPosition: false,
        allPositions: list,
        positions: positions,
      }
    case GET_KEYMANS_COUNT_FAILURE:
      return {
        ...state,
        isLoadingPosition: false,
        error: action.payload.error,
      }
    case COUNT_FOR_CUSTOM_PAGES_REQUEST:
      return {
        ...state,
        isLoadingPosition: true,
      }
    case COUNT_FOR_CUSTOM_PAGES_SUCCESS:
      // かけあわせページ決裁者一覧のデータ
      if (Object.keys(action.payload.data).length === 1 && action.payload.pageType === 'info') {
        return {
          ...state,
          isLoadingPosition: false,
          positionCount: action.payload.data,
        }
      }

      list = OrderedMap({})
      positions = OrderedMap({})
      start =
        (state.pageInfo_position.current_page - 1) *
        state.pageInfo_position.per_page
      end =
        state.pageInfo_position.current_page * state.pageInfo_position.per_page

      HONORIFICS.forEach((item, i) => {
        if (action.payload.data[item.value] !== undefined) {
          list = list.set(item.value, {
            ...item,
            count: action.payload.data[item.value],
          })
        }
      })

      list.slice(start, end).forEach((item, i) => {
        positions = positions.set(item.value, {
          ...item,
          count: action.payload.data[item.value],
        })
      })

      // かけあわせページ役職一覧のデータ
      return {
        ...state,
        isLoadingPosition: false,
        allPositions: list,
        positions: positions,
        pageInfo_position: {
          current_page: parseInt(action.payload.page),
          last_page: Math.ceil(Object.keys(action.payload.data).length / POSITIONS_PER_PAGE),
          per_page: POSITIONS_PER_PAGE,
          total: Object.keys(action.payload.data).length,
        },
      }
    case COUNT_FOR_CUSTOM_PAGES_FAILURE:
      return {
        ...state,
        isLoadingPosition: false,
        error: action.payload.error,
      }
    case GET_KEYMANS_POSITIONS_SUCCESS:
      start = (action.payload.page - 1) * action.payload.per_page
      end = action.payload.page * action.payload.per_page

      list = OrderedMap({})

      totalCount = state.allPositions.size;
      if (state.allPositions.size === HONORIFICS.length){
        HONORIFICS.slice(start, end).forEach((item, i) => {
          list = list.set(item.value, state.allPositions.get(item.value))
        })
      } else {
        state.allPositions.slice(start, end).forEach((item, i) => {
          list = list.set(item.value, state.allPositions.get(item.value))
        })
      }

      return {
        ...state,
        positions: list,
        pageInfo_position: {
          current_page: action.payload.page,
          last_page: Math.ceil(totalCount/ action.payload.per_page),
          per_page: action.payload.per_page,
          total: totalCount,
        },
      }
    default:
      return state
  }
}
