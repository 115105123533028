import {
  GET_KEYMANS_REQUEST,
  GET_KEYMANS_SUCCESS,
  GET_KEYMANS_FAILURE,
  GET_KEYMANS_BY_ID_REQUEST,
  GET_KEYMANS_BY_ID_SUCCESS,
  GET_KEYMANS_BY_ID_FAILURE,
} from "../../constants/action-types"

const { OrderedMap } = require("immutable")

const initialState = {
  keymans: OrderedMap({}),
  isLoadingKeymans: false,
  pageInfo_keyman: null,
  currentKeymanId: null,
  isLoadingKeyman: false,
  keyman: null,
  error: null,
  isInitialized: false,
}

export default (state = initialState, action) => {
  let list

  state = {
    ...state,
    error: null,
  }

  switch (action.type) {
    case GET_KEYMANS_REQUEST:
      return {
        ...state,
        isLoadingKeymans: true,
        isInitialized: true,
        keymans: action.payload.overwrite ? OrderedMap({}) : state.keymans,
        pageInfo_keyman: action.payload.overwrite
          ? null
          : state.pageInfo_keyman,
      }
    case GET_KEYMANS_SUCCESS:
      list = OrderedMap({})

      action.payload.keymans.forEach(item => {
        list = list.set(item.id, item)
      })

      return {
        ...state,
        isLoadingKeymans: false,
        keymans: list,
        pageInfo_keyman: action.payload.pageInfo,
      }
    case GET_KEYMANS_FAILURE:
      return {
        ...state,
        isLoadingKeymans: false,
      }
    case GET_KEYMANS_BY_ID_REQUEST:
      return {
        ...state,
        isLoadingKeyman: true,
        currentKeymanId: action.payload.id,
        keymans: OrderedMap({}),
      }
    case GET_KEYMANS_BY_ID_SUCCESS:
      if (action.payload.id === state.currentKeymanId) {
        return {
          ...state,
          isLoadingKeyman: false,
          keyman: action.payload.item,
        }
      } else {
        return state
      }
    case GET_KEYMANS_BY_ID_FAILURE:
      if (action.payload.id === state.currentKeymanId) {
        return {
          ...state,
          isLoadingKeyman: false,
          error: action.payload.error,
        }
      } else {
        return state
      }
    default:
      return state
  }
}
