import {
  GET_KEYMANS_REQUEST,
  GET_KEYMANS_SUCCESS,
  GET_KEYMANS_FAILURE,
  GET_DEPARTMENTS_BY_ID_SUCCESS,
  GET_RANK_DEPARTMENTS_BY_ID_REQUEST,
  GET_RANK_DEPARTMENTS_BY_ID_SUCCESS,
  GET_RANK_DEPARTMENTS_BY_ID_FAILURE,
  GET_RANK_JSIC_L_DEPARTMENTS_BY_ID_REQUEST,
  GET_RANK_JSIC_L_DEPARTMENTS_BY_ID_SUCCESS,
  GET_RANK_JSIC_L_DEPARTMENTS_BY_ID_FAILURE,
  GET_RANK_CORPORATION_DEPARTMENTS_BY_ID_REQUEST,
  GET_RANK_CORPORATION_DEPARTMENTS_BY_ID_SUCCESS,
  GET_RANK_CORPORATION_DEPARTMENTS_BY_ID_FAILURE,
} from "../../constants/action-types"

const { OrderedMap } = require("immutable")

const initialState = {
  keymans: OrderedMap({}),
  isLoadingKeymans: false,
  pageInfo_keyman: null,
  department: null,
  error: null,
  isLoadingDepartmentRank: true,
  rankStats: {},
  isInitialized: false,
}

export default (state = initialState, action) => {
  let list

  state = {
    ...state,
    error: null,
  }

  switch (action.type) {
    case GET_KEYMANS_REQUEST:
      return {
        ...state,
        isLoadingKeymans: true,
        isInitialized: true,
        keymans: action.payload.overwrite ? OrderedMap({}) : state.keymans,
        pageInfo_keyman: action.payload.overwrite
          ? null
          : state.pageInfo_keyman,
      }
    case GET_KEYMANS_SUCCESS:
      list = OrderedMap({})

      action.payload.keymans.forEach(item => {
        list = list.set(item.id, item)
      })

      return {
        ...state,
        isLoadingKeymans: false,
        keymans: list,
        pageInfo_keyman: action.payload.pageInfo,
      }
    case GET_KEYMANS_FAILURE:
      return {
        ...state,
        isLoadingKeymans: false,
      }
    case GET_DEPARTMENTS_BY_ID_SUCCESS:
      return {
        ...state,
        department: action.payload.department,
      }
    case GET_RANK_DEPARTMENTS_BY_ID_REQUEST:
      return {
        ...state,
        isLoadingDepartmentRank: true,
      }
    case GET_RANK_DEPARTMENTS_BY_ID_SUCCESS:
      return {
        ...state,
        isLoadingDepartmentRank: false,
        rankStats: {
          ...state.rankStats,
          [action.payload.data.key]: action.payload.data,
        },
      }
    case GET_RANK_DEPARTMENTS_BY_ID_FAILURE:
      return {
        ...state,
        isLoadingDepartmentRank: false,
        error: action.payload.error,
      }
    case GET_RANK_JSIC_L_DEPARTMENTS_BY_ID_REQUEST:
      return {
        ...state,
        isLoadingDepartmentRank: true,
      }
    case GET_RANK_JSIC_L_DEPARTMENTS_BY_ID_SUCCESS:
      return {
        ...state,
        isLoadingDepartmentRank: false,
        rankStats: {
          ...state.rankStats,
          [action.payload.data.key]: action.payload.data,
        },
      }
    case GET_RANK_JSIC_L_DEPARTMENTS_BY_ID_FAILURE:
      return {
        ...state,
        isLoadingDepartmentRank: false,
        error: action.payload.error,
      }
    case GET_RANK_CORPORATION_DEPARTMENTS_BY_ID_REQUEST:
      return {
        ...state,
        isLoadingDepartmentRank: true,
      }
    case GET_RANK_CORPORATION_DEPARTMENTS_BY_ID_SUCCESS:
      return {
        ...state,
        isLoadingDepartmentRank: false,
        rankStats: {
          ...state.rankStats,
          [action.payload.data.key]: action.payload.data,
        },
      }
    case GET_RANK_CORPORATION_DEPARTMENTS_BY_ID_FAILURE:
      return {
        ...state,
        isLoadingDepartmentRank: false,
        error: action.payload.error,
      }
    default:
      return state
  }
}
