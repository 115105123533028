import {
  GET_KEYMANS_REQUEST,
  GET_KEYMANS_SUCCESS,
  GET_KEYMANS_FAILURE,
} from "../../constants/action-types"

const { OrderedMap } = require("immutable")

const initialState = {
  keymans: OrderedMap({}),
  isLoadingKeymans: false,
  pageInfo_keyman: null,
  requestPage: 1,
  isInitialized: false,
  keymanCountYstd: " - ",
}

export default (state = initialState, action) => {
  let list

  switch (action.type) {
    case GET_KEYMANS_REQUEST:
      return {
        ...state,
        isLoadingKeymans: true,
        isInitialized: true,
        requestPage: action.payload.page,
        keymans: action.payload.overwrite ? OrderedMap({}) : state.keymans,
        pageInfo_keyman: action.payload.overwrite
          ? null
          : state.pageInfo_keyman,
      }
    case GET_KEYMANS_SUCCESS:
      if (
        action.payload.pageInfo &&
        action.payload.pageInfo.current_page.toString() ===
          state.requestPage.toString()
      ) {
        list = OrderedMap({})

        action.payload.keymans.forEach(item => {
          list = list.set(item.id, item)
        })

        return {
          ...state,
          isLoadingKeymans: false,
          keymans: list,
          pageInfo_keyman: action.payload.pageInfo,
          keymanCountYstd: action.payload.pageInfo && action.payload.pageInfo.total
        }
      } else {
        return state
      }
    case GET_KEYMANS_FAILURE:
      return {
        ...state,
        isLoadingKeymans: false,
      }
    default:
      return state
  }
}
