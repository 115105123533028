export const GET_CORPORATIONS_REQUEST = "GET_CORPORATIONS_REQUEST"
export const GET_CORPORATIONS_SUCCESS = "GET_CORPORATIONS_SUCCESS"
export const GET_CORPORATIONS_FAILURE = "GET_CORPORATIONS_FAILURE"
export const GET_INTENT_CORPORATIONS_REQUEST = "GET_INTENT_CORPORATIONS_REQUEST"
export const GET_INTENT_CORPORATIONS_SUCCESS = "GET_INTENT_CORPORATIONS_SUCCESS"
export const GET_INTENT_CORPORATIONS_FAILURE = "GET_INTENT_CORPORATIONS_FAILURE"
export const GET_KEYMANS_REQUEST = "GET_KEYMANS_REQUEST"
export const GET_KEYMANS_SUCCESS = "GET_KEYMANS_SUCCESS"
export const GET_KEYMANS_FAILURE = "GET_KEYMANS_FAILURE"
export const GET_KEYMANS_HOME_REQUEST = "GET_KEYMANS_HOME_REQUEST"
export const GET_KEYMANS_HOME_SUCCESS = "GET_KEYMANS_HOME_SUCCESS"
export const GET_KEYMANS_HOME_FAILURE = "GET_KEYMANS_HOME_FAILURE"
export const GET_KEYMANS_RELEVANT_REQUEST = "GET_KEYMANS_RELEVANT_REQUEST"
export const GET_KEYMANS_RELEVANT_SUCCESS = "GET_KEYMANS_RELEVANT_SUCCESS"
export const GET_KEYMANS_RELEVANT_FAILURE = "GET_KEYMANS_RELEVANT_FAILURE"
export const GET_KEYMANS_BY_ID_REQUEST = "GET_KEYMANS_BY_ID_REQUEST"
export const GET_KEYMANS_BY_ID_SUCCESS = "GET_KEYMANS_BY_ID_SUCCESS"
export const GET_KEYMANS_BY_ID_FAILURE = "GET_KEYMANS_BY_ID_FAILURE"
export const GET_CORPORATIONS_BY_ID_REQUEST = "GET_CORPORATIONS_BY_ID_REQUEST"
export const GET_CORPORATIONS_BY_ID_SUCCESS = "GET_CORPORATIONS_BY_ID_SUCCESS"
export const GET_CORPORATIONS_BY_ID_FAILURE = "GET_CORPORATIONS_BY_ID_FAILURE"
export const GET_KEYMANS_YESTERDAY_REQUEST = "GET_KEYMANS_YESTERDAY_REQUEST"
export const GET_KEYMANS_YESTERDAY_SUCCESS = "GET_KEYMANS_YESTERDAY_SUCCESS"
export const GET_KEYMANS_YESTERDAY_FAILURE = "GET_KEYMANS_YESTERDAY_FAILURE"
export const GET_KEYMANS_TOTAL_SUCCESS = "GET_KEYMANS_TOTAL_SUCCESS"
export const GET_KEYMANS_TOTAL_FAILURE = "GET_KEYMANS_TOTAL_FAILURE"
export const GET_KEYMANS_COUNT_REQUEST = "GET_KEYMANS_COUNT_REQUEST"
export const GET_KEYMANS_COUNT_SUCCESS = "GET_KEYMANS_COUNT_SUCCESS"
export const GET_KEYMANS_COUNT_FAILURE = "GET_KEYMANS_COUNT_FAILURE"
export const GET_KEYMANS_POSITIONS_SUCCESS = "GET_KEYMANS_POSITIONS_SUCCESS"
export const GET_KEYMANS_DEPARTMENTS_SUCCESS = "GET_KEYMANS_DEPARTMENTS_SUCCESS"
export const GET_DEPARTMENTS_BY_ID_REQUEST = "GET_DEPARTMENTS_BY_ID_REQUEST"
export const GET_DEPARTMENTS_BY_ID_SUCCESS = "GET_DEPARTMENTS_BY_ID_SUCCESS"
export const GET_DEPARTMENTS_BY_ID_FAILURE = "GET_DEPARTMENTS_BY_ID_FAILURE"
export const GET_POSITIONS_BY_ID_SUCCESS = "GET_POSITIONS_BY_ID_SUCCESS"
export const GET_POSITIONS_BY_ID_FAILURE = "GET_POSITIONS_BY_ID_FAILURE"
export const GET_KEYMANS_INDUSTRIES_SUCCESS = "GET_POSITIONS_BY_ID_FAILURE"
export const GET_INDUSTRIES_BY_ID_SUCCESS = "GET_INDUSTRIES_BY_ID_SUCCESS"
export const GET_INDUSTRIES_BY_ID_FAILURE = "GET_INDUSTRIES_BY_ID_FAILURE"
export const COUNT_FOR_CUSTOM_PAGES_REQUEST = "COUNT_FOR_CUSTOM_PAGES_REQUEST"
export const COUNT_FOR_CUSTOM_PAGES_SUCCESS = "COUNT_FOR_CUSTOM_PAGES_SUCCESS"
export const COUNT_FOR_CUSTOM_PAGES_FAILURE = "COUNT_FOR_CUSTOM_PAGES_FAILURE"
export const GET_RANK_DEPARTMENTS_REQUEST = "GET_RANK_DEPARTMENTS_REQUEST"
export const GET_RANK_DEPARTMENTS_SUCCESS = "GET_RANK_DEPARTMENTS_SUCCESS"
export const GET_RANK_DEPARTMENTS_FAILURE = "GET_RANK_DEPARTMENTS_FAILURE"
export const GET_RANK_JSIC_L_DEPARTMENTS_REQUEST = "GET_RANK_JSIC_L_DEPARTMENTS_REQUEST"
export const GET_RANK_JSIC_L_DEPARTMENTS_SUCCESS = "GET_RANK_JSIC_L_DEPARTMENTS_SUCCESS"
export const GET_RANK_JSIC_L_DEPARTMENTS_FAILURE = "GET_RANK_JSIC_L_DEPARTMENTS_FAILURE"
export const GET_RANK_CORPORATION_DEPARTMENTS_REQUEST = "GET_RANK_CORPORATION_DEPARTMENTS_REQUEST"
export const GET_RANK_CORPORATION_DEPARTMENTS_SUCCESS = "GET_RANK_CORPORATION_DEPARTMENTS_SUCCESS"
export const GET_RANK_CORPORATION_DEPARTMENTS_FAILURE = "GET_RANK_CORPORATION_DEPARTMENTS_FAILURE"
export const GET_RANK_JSIC_L_REQUEST = "GET_RANK_JSIC_L_REQUEST"
export const GET_RANK_JSIC_L_SUCCESS = "GET_RANK_JSIC_L_SUCCESS"
export const GET_RANK_JSIC_L_FAILURE = "GET_RANK_JSIC_L_FAILURE"
export const GET_RANK_JSIC_S_REQUEST = "GET_RANK_JSIC_S_REQUEST"
export const GET_RANK_JSIC_S_SUCCESS = "GET_RANK_JSIC_S_SUCCESS"
export const GET_RANK_JSIC_S_FAILURE = "GET_RANK_JSIC_S_FAILURE"
export const GET_RANK_DEPARTMENTS_BY_ID_REQUEST =
  "GET_RANK_DEPARTMENTS_BY_ID_REQUEST"
export const GET_RANK_DEPARTMENTS_BY_ID_SUCCESS =
  "GET_RANK_DEPARTMENTS_BY_ID_SUCCESS"
export const GET_RANK_DEPARTMENTS_BY_ID_FAILURE =
  "GET_RANK_DEPARTMENTS_BY_ID_FAILURE"
export const GET_RANK_JSIC_L_DEPARTMENTS_BY_ID_REQUEST =
  "GET_RANK_JSIC_L_DEPARTMENTS_BY_ID_REQUEST"
export const GET_RANK_JSIC_L_DEPARTMENTS_BY_ID_SUCCESS =
  "GET_RANK_JSIC_L_DEPARTMENTS_BY_ID_SUCCESS"
export const GET_RANK_JSIC_L_DEPARTMENTS_BY_ID_FAILURE =
  "GET_RANK_JSIC_L_DEPARTMENTS_BY_ID_FAILURE"
export const GET_RANK_CORPORATION_DEPARTMENTS_BY_ID_REQUEST =
  "GET_RANK_CORPORATION_DEPARTMENTS_BY_ID_REQUEST"
export const GET_RANK_CORPORATION_DEPARTMENTS_BY_ID_SUCCESS =
  "GET_RANK_CORPORATION_DEPARTMENTS_BY_ID_SUCCESS"
export const GET_RANK_CORPORATION_DEPARTMENTS_BY_ID_FAILURE =
  "GET_RANK_CORPORATION_DEPARTMENTS_BY_ID_FAILURE"
export const GET_RANK_JSIC_L_BY_ID_REQUEST = "GET_RANK_JSIC_L_BY_ID_REQUEST"
export const GET_RANK_JSIC_L_BY_ID_SUCCESS = "GET_RANK_JSIC_L_BY_ID_SUCCESS"
export const GET_RANK_JSIC_L_BY_ID_FAILURE = "GET_RANK_JSIC_L_BY_ID_FAILURE"
export const GET_RANK_JSIC_S_BY_ID_REQUEST = "GET_RANK_JSIC_S_BY_ID_REQUEST"
export const GET_RANK_JSIC_S_BY_ID_SUCCESS = "GET_RANK_JSIC_S_BY_ID_SUCCESS"
export const GET_RANK_JSIC_S_BY_ID_FAILURE = "GET_RANK_JSIC_S_BY_ID_FAILURE"
export const GET_EXECUTIVE_COUNT_REQUEST = "GET_EXECUTIVE_COUNT_REQUEST"
export const GET_EXECUTIVE_COUNT_SUCCESS = "GET_EXECUTIVE_COUNT_SUCCESS"
export const GET_EXECUTIVE_COUNT_FAILURE = "GET_EXECUTIVE_COUNT_FAILURE"
export const POST_CORPORATION_INTENT_CSV_REQUEST = "POST_CORPORATION_INTENT_CSV_REQUEST"
export const POST_CORPORATION_INTENT_CSV_SUCCESS = "POST_CORPORATION_INTENT_CSV_SUCCESS"
export const POST_CORPORATION_INTENT_CSV_FAILURE = "POST_CORPORATION_INTENT_CSV_FAILURE"
