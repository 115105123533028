import {
  GET_KEYMANS_REQUEST,
  GET_KEYMANS_SUCCESS,
  GET_KEYMANS_FAILURE,
  GET_INDUSTRIES_BY_ID_SUCCESS,
  GET_RANK_JSIC_L_BY_ID_REQUEST,
  GET_RANK_JSIC_L_BY_ID_SUCCESS,
  GET_RANK_JSIC_L_BY_ID_FAILURE,
  GET_RANK_JSIC_S_BY_ID_REQUEST,
  GET_RANK_JSIC_S_BY_ID_SUCCESS,
  GET_RANK_JSIC_S_BY_ID_FAILURE,
} from "../../constants/action-types"

const { OrderedMap } = require("immutable")

const initialState = {
  keymans: OrderedMap({}),
  isLoadingKeymans: false,
  pageInfo_keyman: null,
  industry: null,
  error: null,
  isLoadingJsicLRank: false,
  isLoadingJsicSRank: false,
  jsicLRankStats: {},
  jsicSRankStats: {},
  isInitialized: false,
}

export default (state = initialState, action) => {
  let list

  state = {
    ...state,
    error: null,
  }

  switch (action.type) {
    case GET_KEYMANS_REQUEST:
      return {
        ...state,
        isLoadingKeymans: true,
        isInitialized: true,
        keymans: action.payload.overwrite ? OrderedMap({}) : state.keymans,
        pageInfo_keyman: action.payload.overwrite
          ? null
          : state.pageInfo_keyman,
      }
    case GET_KEYMANS_SUCCESS:
      list = OrderedMap({})

      action.payload.keymans.forEach(item => {
        list = list.set(item.id, item)
      })

      return {
        ...state,
        isLoadingKeymans: false,
        keymans: list,
        pageInfo_keyman: action.payload.pageInfo,
      }
    case GET_KEYMANS_FAILURE:
      return {
        ...state,
        isLoadingKeymans: false,
      }
    case GET_INDUSTRIES_BY_ID_SUCCESS:
      return {
        ...state,
        industry: action.payload.industry,
      }
    case GET_RANK_JSIC_L_BY_ID_REQUEST:
      return {
        ...state,
        isLoadingJsicLRank: true,
      }
    case GET_RANK_JSIC_L_BY_ID_SUCCESS:
      return {
        ...state,
        isLoadingJsicLRank: false,
        jsicLRankStats: {
          ...state.jsicLRankStats,
          [action.payload.data.jsic_l]: action.payload.data,
        },
      }
    case GET_RANK_JSIC_L_BY_ID_FAILURE:
      return {
        ...state,
        isLoadingJsicLRank: false,
        error: action.payload.error,
      }
    case GET_RANK_JSIC_S_BY_ID_REQUEST:
      return {
        ...state,
        isLoadingJsicSRank: true,
      }
    case GET_RANK_JSIC_S_BY_ID_SUCCESS:
      return {
        ...state,
        isLoadingJsicSRank: false,
        jsicSRankStats: {
          ...state.jsicSRankStats,
          [action.payload.data.jsic_s]: action.payload.data,
        },
      }
    case GET_RANK_JSIC_S_BY_ID_FAILURE:
      return {
        ...state,
        isLoadingJsicSRank: false,
        error: action.payload.error,
      }
    default:
      return state
  }
}
