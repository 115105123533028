import {
  GET_KEYMANS_COUNT_REQUEST,
  GET_KEYMANS_COUNT_SUCCESS,
  GET_KEYMANS_COUNT_FAILURE,
  GET_KEYMANS_DEPARTMENTS_SUCCESS,
  GET_RANK_DEPARTMENTS_REQUEST,
  GET_RANK_DEPARTMENTS_SUCCESS,
  GET_RANK_DEPARTMENTS_FAILURE,
  GET_RANK_JSIC_L_DEPARTMENTS_REQUEST,
  GET_RANK_JSIC_L_DEPARTMENTS_SUCCESS,
  GET_RANK_JSIC_L_DEPARTMENTS_FAILURE,
  GET_RANK_CORPORATION_DEPARTMENTS_REQUEST,
  GET_RANK_CORPORATION_DEPARTMENTS_SUCCESS,
  GET_RANK_CORPORATION_DEPARTMENTS_FAILURE,
  COUNT_FOR_CUSTOM_PAGES_REQUEST,
  COUNT_FOR_CUSTOM_PAGES_SUCCESS,
  COUNT_FOR_CUSTOM_PAGES_FAILURE,
} from "../../constants/action-types"
import {
  DEPARTMENTS,
  DEPARTMENT_PER_PAGE,
  KEYMAN_RANK_TYPE,
} from "../../constants/constants"

const { OrderedMap } = require("immutable")

let all_departments = OrderedMap({}),
  departments = OrderedMap({})
DEPARTMENTS.forEach((item, i) => {
  all_departments = all_departments.set(item.value, item)
})

DEPARTMENTS.slice(0, DEPARTMENT_PER_PAGE).forEach((item, i) => {
  departments = departments.set(item.value, item)
})

const initialState = {
  allDepartments: all_departments,
  departments: departments,
  isLoadingDepartments: false,
  pageInfo_department: {
    current_page: 1,
    last_page: Math.ceil(DEPARTMENTS.length / DEPARTMENT_PER_PAGE),
    per_page: DEPARTMENT_PER_PAGE,
    total: DEPARTMENTS.length,
  },
  error: null,
  isLoadingDepartmentRank: false,
  letterRankStats: null,
  reactionRankStats: null,
  shoudanRankStats: null,
  departmentCount: ' - ',
}

export default (state = initialState, action) => {
  let list, departments, start, end, totalCount

  state = {
    ...state,
    error: null,
  }

  switch (action.type) {
    case GET_KEYMANS_COUNT_REQUEST:
      return {
        ...state,
        isLoadingDepartments: true,
      }
    case GET_KEYMANS_COUNT_SUCCESS:
      list = OrderedMap({})
      departments = OrderedMap({})
      start =
        (state.pageInfo_department.current_page - 1) *
        state.pageInfo_department.per_page
      end =
        state.pageInfo_department.current_page *
        state.pageInfo_department.per_page

      DEPARTMENTS.forEach((item, i) => {
        list = list.set(item.value, {
          ...item,
          count: action.payload.data["department"][item.value],
        })
      })

      DEPARTMENTS.slice(start, end).forEach((item, i) => {
        departments = departments.set(item.value, {
          ...item,
          count: action.payload.data["department"][item.value],
        })
      })

      return {
        ...state,
        isLoadingDepartments: false,
        allDepartments: list,
        departments: departments,
      }
    case GET_KEYMANS_COUNT_FAILURE:
      return {
        ...state,
        isLoadingDepartments: false,
        error: action.payload.error,
      }
    case GET_KEYMANS_DEPARTMENTS_SUCCESS:
      start = (action.payload.page - 1) * action.payload.per_page
      end = action.payload.page * action.payload.per_page

      list = OrderedMap({})
      totalCount = state.allDepartments.size;
      if (state.allDepartments.size === DEPARTMENTS.length){
        DEPARTMENTS.slice(start, end).forEach((item, i) => {
          list = list.set(item.value, state.allDepartments.get(item.value))
        })
      } else {
        state.allDepartments.slice(start, end).forEach((item, i) => {
          list = list.set(item.value, state.allDepartments.get(item.value))
        })
      }

      return {
        ...state,
        departments: list,
        pageInfo_department: {
          current_page: action.payload.page,
          last_page: Math.ceil(totalCount / action.payload.per_page),
          per_page: action.payload.per_page,
          total: totalCount,
        },
      }
    case GET_RANK_DEPARTMENTS_REQUEST:
      return {
        ...state,
        isLoadingDepartmentRank: true,
      }
    case GET_RANK_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        isLoadingDepartmentRank: false,
        letterRankStats: action.payload.data[KEYMAN_RANK_TYPE.LETTER],
        reactionRankStats: action.payload.data[KEYMAN_RANK_TYPE.REACTION],
        shoudanRankStats: action.payload.data[KEYMAN_RANK_TYPE.SHOUDAN],
      }
    case GET_RANK_DEPARTMENTS_FAILURE:
      return {
        ...state,
        isLoadingDepartmentRank: false,
        error: action.payload.error,
      }
    case GET_RANK_JSIC_L_DEPARTMENTS_REQUEST:
      return {
        ...state,
        isLoadingDepartmentRank: true,
      }
    case GET_RANK_JSIC_L_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        isLoadingDepartmentRank: false,
        letterRankStats: action.payload.data[KEYMAN_RANK_TYPE.LETTER],
        reactionRankStats: action.payload.data[KEYMAN_RANK_TYPE.REACTION],
        shoudanRankStats: action.payload.data[KEYMAN_RANK_TYPE.SHOUDAN],
      }
    case GET_RANK_JSIC_L_DEPARTMENTS_FAILURE:
      return {
        ...state,
        isLoadingDepartmentRank: false,
        error: action.payload.error,
      }
    case GET_RANK_CORPORATION_DEPARTMENTS_REQUEST:
      return {
        ...state,
        isLoadingDepartmentRank: true,
      }
    case GET_RANK_CORPORATION_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        isLoadingDepartmentRank: false,
        letterRankStats: action.payload.data[KEYMAN_RANK_TYPE.LETTER],
        reactionRankStats: action.payload.data[KEYMAN_RANK_TYPE.REACTION],
        shoudanRankStats: action.payload.data[KEYMAN_RANK_TYPE.SHOUDAN],
      }
    case GET_RANK_CORPORATION_DEPARTMENTS_FAILURE:
      return {
        ...state,
        isLoadingDepartmentRank: false,
        error: action.payload.error,
      }
    case COUNT_FOR_CUSTOM_PAGES_REQUEST:
      return {
        ...state,
        isLoadingDepartments: true,
      }
    case COUNT_FOR_CUSTOM_PAGES_SUCCESS:
      // かけあわせページ決裁者一覧のデータ
      if (Object.keys(action.payload.data).length === 1 && action.payload.pageType === 'info') {
        return {
          ...state,
          isLoadingDepartments: false,
          departmentCount: action.payload.data,
        }
      }

      list = OrderedMap({})
      departments = OrderedMap({})
      start =
        (state.pageInfo_department.current_page - 1) *
        state.pageInfo_department.per_page
      end =
        state.pageInfo_department.current_page *
        state.pageInfo_department.per_page

      DEPARTMENTS.forEach((item, i) => {
        if (action.payload.data[item.value] !== undefined) {
          list = list.set(item.value, {
            ...item,
            count: action.payload.data[item.value],
          })
        }
      })

      list.slice(start, end).forEach((item, i) => {
        departments = departments.set(item.value, {
          ...item,
          count: action.payload.data[item.value],
        })
      })

      // かけあわせページ部門一覧のデータ
      return {
        ...state,
        isLoadingDepartments: false,
        allDepartments: list,
        departments: departments,
        pageInfo_department: {
          current_page: parseInt(action.payload.page),
          last_page: Math.ceil(Object.keys(action.payload.data).length / DEPARTMENT_PER_PAGE),
          per_page: DEPARTMENT_PER_PAGE,
          total: Object.keys(action.payload.data).length,
        },
      }
    case COUNT_FOR_CUSTOM_PAGES_FAILURE:
      return {
        ...state,
        isLoadingDepartments: false,
        error: action.payload.error,
      }
    default:
      return state
  }
}
