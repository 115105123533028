import {
  GET_KEYMANS_REQUEST,
  GET_KEYMANS_SUCCESS,
  GET_KEYMANS_FAILURE,
  GET_POSITIONS_BY_ID_SUCCESS,
} from "../../constants/action-types"

const { OrderedMap } = require("immutable")

const initialState = {
  keymans: OrderedMap({}),
  isLoadingKeymans: false,
  pageInfo_keyman: null,
  position: null,
  error: null,
  isInitialized: false,
}

export default (state = initialState, action) => {
  let list

  state = {
    ...state,
    error: null,
  }

  switch (action.type) {
    case GET_KEYMANS_REQUEST:
      return {
        ...state,
        isLoadingKeymans: true,
        isInitialized: true,
        keymans: action.payload.overwrite ? OrderedMap({}) : state.keymans,
        pageInfo_keyman: action.payload.overwrite
          ? null
          : state.pageInfo_keyman,
      }
    case GET_KEYMANS_SUCCESS:
      list = OrderedMap({})

      action.payload.keymans.forEach(item => {
        list = list.set(item.id, item)
      })

      return {
        ...state,
        isLoadingKeymans: false,
        keymans: list,
        pageInfo_keyman: action.payload.pageInfo,
      }
    case GET_KEYMANS_FAILURE:
      return {
        ...state,
        isLoadingKeymans: false,
      }
    case GET_POSITIONS_BY_ID_SUCCESS:
      return {
        ...state,
        position: action.payload.position,
      }
    default:
      return state
  }
}
