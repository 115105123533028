import {
  GET_CORPORATIONS_REQUEST,
  GET_CORPORATIONS_SUCCESS,
  GET_CORPORATIONS_FAILURE,
  GET_KEYMANS_HOME_REQUEST,
  GET_KEYMANS_HOME_SUCCESS,
  GET_KEYMANS_HOME_FAILURE,
} from "../../constants/action-types"

const { OrderedMap } = require("immutable")

const initialState = {
  keymans: OrderedMap({}),
  corporations: OrderedMap({}),
  isLoadingKeymans: false,
  isLoadingCorps: false,
  pageInfo_keyman: null,
  pageInfo_corp: null,
  isInitialized: false,
}

export default (state = initialState, action) => {
  let list

  switch (action.type) {
    case GET_KEYMANS_HOME_REQUEST:
      return {
        ...state,
        isLoadingKeymans: true,
        isInitialized: true,
        keymans: action.payload.overwrite ? OrderedMap({}) : state.keymans,
        pageInfo_keyman: action.payload.overwrite
          ? null
          : state.pageInfo_keyman,
      }
    case GET_KEYMANS_HOME_SUCCESS:
      list = OrderedMap({})

      action.payload.keymans.forEach(item => {
        list = list.set(item.id, item)
      })

      return {
        ...state,
        isLoadingKeymans: false,
        keymans: list,
        pageInfo_keyman: action.payload.pageInfo,
      }
    case GET_KEYMANS_HOME_FAILURE:
      return {
        ...state,
        isLoadingKeymans: false,
      }
    case GET_CORPORATIONS_REQUEST:
      return {
        ...state,
        isLoadingCorps: true,
        corporations: action.payload.overwrite
          ? OrderedMap({})
          : state.corporations,
        pageInfo_corp: action.payload.overwrite ? null : state.pageInfo_corp,
      }
    case GET_CORPORATIONS_SUCCESS:
      list = OrderedMap({})

      action.payload.corporations.forEach(item => {
        list = list.set(item.id, item)
      })

      return {
        ...state,
        isLoadingCorps: false,
        corporations: list,
        pageInfo_corp: action.payload.pageInfo,
      }
    case GET_CORPORATIONS_FAILURE:
      return {
        ...state,
        isLoadingCorps: false,
      }
    default:
      return state
  }
}
