import {
  GET_INTENT_CORPORATIONS_REQUEST,
  GET_INTENT_CORPORATIONS_SUCCESS,
  GET_INTENT_CORPORATIONS_FAILURE,
} from "../../constants/action-types"

const { OrderedMap } = require("immutable")

const initialState = {
  intentCorporations: OrderedMap({}),
  isLoadingIntentCorps: false,
  keymanTotalCountInIntentCorporations: 0,
  pageInfo_intentCorp: null,
  requestPage: 1,
  isInitialized: false,
}

export default (state = initialState, action) => {
  let list

  switch (action.type) {
    case GET_INTENT_CORPORATIONS_REQUEST:
      return {
        ...state,
        isLoadingIntentCorps: true,
        intentCorporations: action.payload.overwrite
          ? OrderedMap({})
          : state.intentCorporations,
        keymanTotalCountInIntentCorporations: action.payload.overwrite ? 0 : state.keymanTotalCountInIntentCorporations,
        pageInfo_intentCorp: action.payload.overwrite ? null : state.pageInfo_intentCorp,
        requestPage: action.payload.page,
        isInitialized: true,
      }
    case GET_INTENT_CORPORATIONS_SUCCESS:
      if (
        action.payload.pageInfo &&
        action.payload.pageInfo.current_page.toString() ===
          state.requestPage.toString()
      ) {
        list = OrderedMap({})

        action.payload.intentCorporations.forEach(item => {
          list = list.set(item.id, item)
        })

        return {
          ...state,
          isLoadingIntentCorps: false,
          intentCorporations: list,
          keymanTotalCountInIntentCorporations:action.payload.pageInfo.keyman_total_count,
          pageInfo_intentCorp: action.payload.pageInfo,
        }
      } else {
        return state
      }
    case GET_INTENT_CORPORATIONS_FAILURE:
      return {
        ...state,
        isLoadingIntentCorps: false,
      }
    default:
      return state
  }
}
