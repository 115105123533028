export const KEYMAN_PER_PAGE = 10
export const CORP_PER_PAGE = 10
export const DEPARTMENT_PER_PAGE = 10
export const POSITIONS_PER_PAGE = 10
export const INDUSTRIES_PER_PAGE = 10
export const INTENT_PER_PAGE = 10
export const TOP_N_ITEMS = 3
export const RELOCATION_LAST_3_YEARS = 3
export const DISPLAY_LIMIT = 10000 // KLに合わせて表示件数の上限を1万件とする

// 役職
export const HONORIFICS = [
  { name: "代表取締役", value: "president" },
  { name: "取締役", value: "director" },
  { name: "COO", value: "coo" },
  { name: "CTO", value: "cto" },
  { name: "CFO", value: "cfo" },
  { name: "CHRO", value: "chro" },
  { name: "CSO", value: "cso" },
  { name: "CDO", value: "cdo" },
  { name: "CIO", value: "cio" },
  { name: "CISO", value: "ciso" },
  { name: "CMO", value: "cmo" },
  { name: "執行役員", value: "corporate_officer" },
  { name: "顧問", value: "adviser" },
  { name: "相談役", value: "counselor" },
  { name: "専務", value: "senior_managing_director" },
  { name: "常務", value: "managing_director" },
  { name: "監査役", value: "auditor" },
  { name: "支社長", value: "branch_president" },
  { name: "部長", value: "department_manager" },
  { name: "次長", value: "assistant_department_manager" },
  { name: "センター長", value: "center_manager" },
  { name: "工場長", value: "factory_manager" },
  { name: "マネージャー", value: "manager" },
  { name: "ディレクター", value: "other_director" },
  { name: "室長", value: "chief" },
  { name: "課長", value: "section_chief" },
  { name: "所長", value: "center_president" },
  { name: "リーダー", value: "leader" },
  { name: "主査", value: "chief_examiner" },
  { name: "その他", value: "other" },
]

//部門
export const DEPARTMENTS = [
  { name: "経営企画", value: "corporate_planning" },
  { name: "経営推進", value: "management_promotion" },
  { name: "社長室", value: "president_office" },
  { name: "秘書室", value: "secretarial_office" },
  { name: "人事・HR", value: "human_resources" },
  { name: "人材・人財", value: "human_capital" },
  { name: '情報システム', value: 'information_system'},
  { name: 'IT', value: 'it'},
  { name: '情報通信', value: 'information_communication'},
  { name: 'システム', value: 'system'},
  { name: 'エンジニアリング', value: 'engineering'},
  { name: "総務", value: "general_affairs" },
  { name: "品質管理", value: "quality_management" },
  { name: "管理", value: "management" },
  { name: "経理・財務", value: "accounting_finance" },
  { name: "広報・IR", value: "public_relations_ir" },
  { name: "CSR", value: "csr" },
  { name: "コーポレートコミュニケーション", value: "corporate_communication" },
  { name: "コンプライアンス", value: "compliance" },
  { name: "リスク管理", value: "risk_management" },
  { name: "知的財産", value: "intellectual_property" },
  { name: "営業企画", value: "sales_planning" },
  { name: "営業推進", value: "sales_promotion" },
  { name: "事業戦略", value: "business_strategy" },
  { name: "EC", value: "ec" },
  { name: "営業", value: "sales" },
  { name: "海外・グローバル", value: "overseas" },
  { name: "輸出・輸入", value: "export_import" },
  { name: "法務", value: "legal" },
  { name: "製造", value: "manufacturing" },
  { name: "購買", value: "purchasing" },
  { name: "商品開発", value: "product_development" },
  { name: "システム開発", value: "system_development" },
  { name: "マーケティング", value: "marketing" },
  { name: "販促・販売・プロモーション", value: "promotion_sales" },
  { name: "物流・SCM", value: "distribution" },
  { name: "流通", value: "flow" },
  { name: "新規事業", value: "new_business" },
  { name: "事業企画", value: "business_plan" },
  { name: "イノベーション", value: "innovation" },
  { name: "M&A・提携", value: "partnership" },
  { name: "生産", value: "production" },
  { name: "設計", value: "architect" },
  { name: "エレクトロニクス", value: "electronics" },
  { name: "技術", value: "technology" },
  { name: "研究開発・R&D", value: "r_and_d" },
  { name: "ブランド", value: "brand" },
  { name: "カスタマーサービス", value: "customer_service" },
  { name: "健康経営", value: "health_management" },
  { name: "働き方改革", value: "work_style_reform" },
  { name: "SDGs", value: "sdgs" },
  { name: "DX・デジタルトランスフォーメーション", value: "dx" },
  { name: "ICT", value: "ict" },
  { name: "ダイバーシティ", value: "diversity" },
  { name: "情報セキュリティ", value: "information_security" },
  { name: "ESG", value: "esg" },
  { name: "IoT", value: "iot" },
  { name: "サステナブル", value: "sustainable" },
  { name: "デジタルイノベーション", value: "digital_innovation" },
  { name: "ロボット", value: "robot" },
  { name: "AI・人工知能", value: "ai" },
  { name: "ソリューション", value: "solution" },
  { name: "戦略", value: "strategy" },
  { name: "開発", value: "development" },
  { name: "調達", value: "supplies" },
  { name: "企画", value: "planning" },
  { name: "業務推進", value: "operations" },
  { name: "事業推進", value: "business_promotion" },
  { name: "コンサルティング", value: "consulting" },
  { name: "監査", value: "audit" },
  { name: "資源・エネルギー", value: "resources_energy" },
  { name: "資材・資本", value: "materials" },
  { name: "貿易・渉外", value: "trade" },
  { name: "リテール", value: "retail" },
  { name: "ファイナンス", value: "finance" },
  { name: "デザイン", value: "design" },
  { name: "セキュリティ", value: "security" },
  { name: "データ", value: "data" },
  { name: "バイオ", value: "bio" },
  { name: "テクノロジー", value: "new_technology" },
  { name: "メディア", value: "media" },
  { name: "ヘルスケア", value: "health_care" },
  { name: "その他", value: "other" },
]

//業界
export const INDUSTRIES = [
  {
    value: "製造業",
    submenu: [
      { value: "管理，補助的経済活動を行う事業所（09食料品製造業）" },
      { value: "畜産食料品製造業" },
      { value: "水産食料品製造業" },
      { value: "野菜缶詰・果実缶詰・農産保存食料品製造業" },
      { value: "調味料製造業" },
      { value: "糖類製造業" },
      { value: "精穀・製粉業" },
      { value: "パン・菓子製造業" },
      { value: "動植物油脂製造業" },
      { value: "その他の食料品製造業" },
      { value: "管理，補助的経済活動を行う事業所（10飲料・たばこ・飼料製造業）" },
      { value: "清涼飲料製造業" },
      { value: "酒類製造業" },
      { value: "茶・コーヒー製造業（清涼飲料を除く）" },
      { value: "製氷業" },
      { value: "たばこ製造業" },
      { value: "飼料・有機質肥料製造業" },
      { value: "管理，補助的経済活動を行う事業所（11繊維工業）" },
      { value: "製糸業，紡績業，化学繊維・ねん糸等製造業" },
      { value: "織物業" },
      { value: "ニット生地製造業" },
      { value: "染色整理業" },
      { value: "綱・網・レース・繊維粗製品製造業" },
      { value: "外衣・シャツ製造業（和式を除く）" },
      { value: "下着類製造業" },
      { value: "和装製品・その他の衣服・繊維製身の回り品製造業" },
      { value: "その他の繊維製品製造業" },
      { value: "管理，補助的経済活動を行う事業所（12木材・木製品製造業）" },
      { value: "製材業，木製品製造業" },
      { value: "造作材・合板・建築用組立材料製造業" },
      { value: "木製容器製造業（竹，とうを含む）" },
      { value: "その他の木製品製造業(竹，とうを含む)" },
      { value: "管理，補助的経済活動を行う事業所（13家具・装備品製造業）" },
      { value: "家具製造業" },
      { value: "宗教用具製造業" },
      { value: "建具製造業" },
      { value: "その他の家具・装備品製造業" },
      { value: "管理，補助的経済活動を行う事業所（14パルプ・紙・紙加工品製造業）" },
      { value: "パルプ製造業" },
      { value: "紙製造業" },
      { value: "加工紙製造業" },
      { value: "紙製品製造業" },
      { value: "紙製容器製造業" },
      { value: "その他のパルプ・紙・紙加工品製造業" },
      { value: "管理，補助的経済活動を行う事業所（15印刷・同関連業）" },
      { value: "印刷業" },
      { value: "製版業" },
      { value: "製本業，印刷物加工業" },
      { value: "印刷関連サービス業" },
      { value: "管理，補助的経済活動を行う事業所（16化学工業）" },
      { value: "化学肥料製造業" },
      { value: "無機化学工業製品製造業" },
      { value: "有機化学工業製品製造業" },
      { value: "油脂加工製品・石けん・合成洗剤・界面活性剤・塗料製造業" },
      { value: "医薬品製造業" },
      { value: "化粧品・歯磨・その他の化粧用調整品製造業" },
      { value: "その他の化学工業" },
      { value: "管理，補助的経済活動を行う事業所（17石油製品・石炭製品製造業）" },
      { value: "石油精製業" },
      { value: "潤滑油・グリース製造業（石油精製業によらないもの）" },
      { value: "コークス製造業" },
      { value: "舗装材料製造業" },
      { value: "その他の石油製品・石炭製品製造業" },
      { value: "管理，補助的経済活動を行う事業所（18プラスチック製品製造業）" },
      { value: "プラスチック板・棒・管・継手・異形押出製品製造業" },
      { value: "プラスチックフィルム・シート・床材・合成皮革製造業" },
      { value: "工業用プラスチック製品製造業" },
      { value: "発泡・強化プラスチック製品製造業" },
      { value: "プラスチック成形材料製造業（廃プラスチックを含む）" },
      { value: "その他のプラスチック製品製造業" },
      { value: "管理，補助的経済活動を行う事業所（19ゴム製品製造業）" },
      { value: "タイヤ・チューブ製造業" },
      { value: "ゴム製・プラスチック製履物・同附属品製造業" },
      { value: "ゴムベルト・ゴムホース・工業用ゴム製品製造業" },
      { value: "その他のゴム製品製造業" },
      { value: "管理，補助的経済活動を行う事業所（20なめし革・同製品・毛皮製造業）" },
      { value: "なめし革製造業" },
      { value: "工業用革製品製造業（手袋を除く）" },
      { value: "革製履物用材料・同附属品製造業" },
      { value: "革製履物製造業" },
      { value: "革製手袋製造業" },
      { value: "かばん製造業" },
      { value: "袋物製造業" },
      { value: "毛皮製造業" },
      { value: "その他のなめし革製品製造業" },
      { value: "管理，補助的経済活動を行う事業所（21窯業・土石製品製造業）" },
      { value: "ガラス・同製品製造業" },
      { value: "セメント・同製品製造業" },
      { value: "建設用粘土製品製造業（陶磁器製を除く)" },
      { value: "陶磁器・同関連製品製造業" },
      { value: "耐火物製造業" },
      { value: "炭素・黒鉛製品製造業" },
      { value: "研磨材・同製品製造業" },
      { value: "骨材・石工品等製造業" },
      { value: "その他の窯業・土石製品製造業" },
      { value: "管理，補助的経済活動を行う事業所（22鉄鋼業）" },
      { value: "製鉄業"},
      { value: "製鋼・製鋼圧延業" },
      { value: "製鋼を行わない鋼材製造業（表面処理鋼材を除く）" },
      { value: "表面処理鋼材製造業" },
      { value: "鉄素形材製造業" },
      { value: "その他の鉄鋼業" },
      { value: "管理，補助的経済活動を行う事業所（23非鉄金属製造業）" },
      { value: "非鉄金属第１次製錬・精製業" },
      { value: "非鉄金属第２次製錬・精製業（非鉄金属合金製造業を含む）" },
      { value: "非鉄金属・同合金圧延業（抽伸，押出しを含む）" },
      { value: "電線・ケーブル製造業" },
      { value: "非鉄金属素形材製造業" },
      { value: "その他の非鉄金属製造業" },
      { value: "管理，補助的経済活動を行う事業所（24金属製品製造業）" },
      { value: "ブリキ缶・その他のめっき板等製品製造業" },
      { value: "洋食器・刃物・手道具・金物類製造業" },
      { value: "暖房・調理等装置,配管工事用附属品製造業" },
      { value: "建設用・建築用金属製品製造業（製缶板金業を含む)" },
      { value: "金属素形材製品製造業" },
      { value: "金属被覆・彫刻業，熱処理業（ほうろう鉄器を除く）" },
      { value: "金属線製品製造業（ねじ類を除く)" },
      { value: "ボルト・ナット・リベット・小ねじ・木ねじ等製造業" },
      { value: "その他の金属製品製造業" },
      { value: "管理，補助的経済活動を行う事業所（25はん用機械器具製造業）" },
      { value: "ボイラ・原動機製造業" },
      { value: "ポンプ・圧縮機器製造業" },
      { value: "一般産業用機械・装置製造業" },
      { value: "その他のはん用機械・同部分品製造業" },
      { value: "管理，補助的経済活動を行う事業所（26生産用機械器具製造業）" },
      { value: "農業用機械製造業（農業用器具を除く）" },
      { value: "建設機械・鉱山機械製造業" },
      { value: "繊維機械製造業" },
      { value: "生活関連産業用機械製造業" },
      { value: "基礎素材産業用機械製造業" },
      { value: "金属加工機械製造業" },
      { value: "半導体・フラットパネルディスプレイ製造装置製造業" },
      { value: "その他の生産用機械・同部分品製造業" },
      { value: "管理，補助的経済活動を行う事業所（27業務用機械器具製造業）" },
      { value: "事務用機械器具製造業" },
      { value: "サービス用・娯楽用機械器具製造業" },
      { value: "計量器・測定器・分析機器・試験機・測量機械器具・理化学機械器具製造業" },
      { value: "医療用機械器具・医療用品製造業" },
      { value: "光学機械器具・レンズ製造業" },
      { value: "武器製造業" },
      { value: "管理，補助的経済活動を行う事業所（28電子部品・デバイス・電子回路製造業）" },
      { value: "電子デバイス製造業" },
      { value: "電子部品製造業" },
      { value: "記録メディア製造業" },
      { value: "電子回路製造業" },
      { value: "ユニット部品製造業" },
      { value: "その他の電子部品・デバイス・電子回路製造業" },
      { value: "管理，補助的経済活動を行う事業所（29電気機械器具製造業）" },
      { value: "発電用・送電用・配電用電気機械器具製造業" },
      { value: "産業用電気機械器具製造業" },
      { value: "民生用電気機械器具製造業" },
      { value: "電球・電気照明器具製造業" },
      { value: "電池製造業" },
      { value: "電子応用装置製造業" },
      { value: "電気計測器製造業" },
      { value: "その他の電気機械器具製造業" },
      { value: "管理，補助的経済活動を行う事業所（30情報通信機械器具製造業）" },
      { value: "通信機械器具・同関連機械器具製造業" },
      { value: "映像・音響機械器具製造業" },
      { value: "電子計算機・同附属装置製造業" },
      { value: "管理，補助的経済活動を行う事業所（31輸送用機械器具製造業）" },
      { value: "自動車・同附属品製造業" },
      { value: "鉄道車両・同部分品製造業" },
      { value: "船舶製造・修理業，舶用機関製造業" },
      { value: "航空機・同附属品製造業" },
      { value: "産業用運搬車両・同部分品・附属品製造業" },
      { value: "その他の輸送用機械器具製造業" },
      { value: "管理，補助的経済活動を行う事業所（32その他の製造業）" },
      { value: "貴金属・宝石製品製造業" },
      { value: "装身具・装飾品・ボタン・同関連品製造業（貴金属・宝石製を除く）" },
      { value: "時計・同部分品製造業" },
      { value: "楽器製造業" },
      { value: "がん具・運動用具製造業" },
      { value: "ペン・鉛筆・絵画用品・その他の事務用品製造業" },
      { value: "漆器製造業" },
      { value: "畳等生活雑貨製品製造業" },
      { value: "他に分類されない製造業" }
    ]
  },
  {
    value: "鉱業，採石業，砂利採取業",
    submenu: [
      { value: "管理，補助的経済活動を行う事業所（05鉱業，採石業，砂利採取業）" },
      { value: "金属鉱業"},
      { value: "石炭・亜炭鉱業" },
      { value: "原油・天然ガス鉱業" },
      { value: "採石業，砂・砂利・玉石採取業" },
      { value: "窯業原料用鉱物鉱業（耐火物・陶磁器・ガラス・セメント原料用に限る）" },
      { value: "その他の鉱業" }
    ]
  },
  {
    value: "サービス業（他に分類されないもの）",
    submenu: [
      { value: "管理，補助的経済活動を行う事業所（88廃棄物処理業）" },
      { value: "一般廃棄物処理業" },
      { value: "産業廃棄物処理業" },
      { value: "その他の廃棄物処理業" },
      { value: "管理，補助的経済活動を行う事業所（89自動車整備業）" },
      { value: "自動車整備業" },
      { value: "管理，補助的経済活動を行う事業所（90機械等修理業）" },
      { value: "機械修理業（電気機械器具を除く）" },
      { value: "電気機械器具修理業" },
      { value: "表具業" },
      { value: "その他の修理業" },
      { value: "管理，補助的経済活動を行う事業所（91職業紹介・労働者派遣業）" },
      { value: "職業紹介業" },
      { value: "労働者派遣業" },
      { value: "管理，補助的経済活動を行う事業所（92その他の事業サービス業）" },
      { value: "速記・ワープロ入力・複写業" },
      { value: "建物サービス業" },
      { value: "警備業" },
      { value: "他に分類されない事業サービス業" },
      { value: "経済団体" },
      { value: "労働団体" },
      { value: "学術・文化団体" },
      { value: "政治団体" },
      { value: "他に分類されない非営利的団体" },
      { value: "神道系宗教" },
      { value: "仏教系宗教" },
      { value: "キリスト教系宗教" },
      { value: "その他の宗教" },
      { value: "管理，補助的経済活動を行う事業所（95その他のサービス業）" },
      { value: "集会場" },
      { value: "と畜場"},
      { value: "他に分類されないサービス業" },
      { value: "外国公館" },
      { value: "その他の外国公務" }
    ]
  },
  {
    value: "複合サービス事業",
    submenu: [
      { value: "管理，補助的経済活動を行う事業所（86郵便局）" },
      { value: "郵便局" },
      { value: "郵便局受託業" },
      { value: "管理，補助的経済活動を行う事業所（87協同組合）" },
      { value: "農林水産業協同組合（他に分類されないもの）" },
      { value: "事業協同組合（他に分類されないもの）" }
    ]
  },
  {
    value: "建設業",
    submenu: [
      { value: "管理，補助的経済活動を行う事業所（06総合工事業）" },
      { value: "一般土木建築工事業" },
      { value: "土木工事業（舗装工事業を除く）" },
      { value: "舗装工事業" },
      { value: "建築工事業(木造建築工事業を除く)" },
      { value: "木造建築工事業" },
      { value: "建築リフォーム工事業" },
      { value: "管理，補助的経済活動を行う事業所（07職別工事業）" },
      { value: "大工工事業" },
      { value: "とび・土工・コンクリート工事業" },
      { value: "鉄骨・鉄筋工事業" },
      { value: "石工・れんが・タイル・ブロック工事業" },
      { value: "左官工事業" },
      { value: "板金・金物工事業" },
      { value: "塗装工事業" },
      { value: "床・内装工事業" },
      { value: "その他の職別工事業" },
      { value: "管理，補助的経済活動を行う事業所（08設備工事業）" },
      { value: "電気工事業" },
      { value: "電気通信・信号装置工事業" },
      { value: "管工事業（さく井工事業を除く）" },
      { value: "機械器具設置工事業" },
      { value: "その他の設備工事業" }
    ]
  },
  {
    value: "学術研究，専門・技術サービス業",
    submenu: [
      { value: "管理，補助的経済活動を行う事業所（71学術・開発研究機関）" },
      { value: "自然科学研究所" },
      { value: "人文・社会科学研究所" },
      { value: "管理，補助的経済活動を行う事業所（72専門サービス業）" },
      { value: "法律事務所，特許事務所" },
      { value: "公証人役場，司法書士事務所，土地家屋調査士事務所" },
      { value: "行政書士事務所" },
      { value: "公認会計士事務所，税理士事務所" },
      { value: "社会保険労務士事務所" },
      { value: "デザイン業" },
      { value: "著述・芸術家業" },
      { value: "経営コンサルタント業，純粋持株会社" },
      { value: "その他の専門サービス業" },
      { value: "管理，補助的経済活動を行う事業所（73広告業）" },
      { value: "広告業" },
      { value: "管理，補助的経済活動を行う事業所（74技術サービス業）" },
      { value: "獣医業" },
      { value: "土木建築サービス業" },
      { value: "機械設計業" },
      { value: "商品・非破壊検査業" },
      { value: "計量証明業" },
      { value: "写真業" },
      { value: "その他の技術サービス業" }
    ]
  },
  {
    value: "医療，福祉",
    submenu: [
      { value: "管理，補助的経済活動を行う事業所（83医療業）" },
      { value: "病院" },
      { value: "一般診療所" },
      { value: "歯科診療所" },
      { value: "助産・看護業" },
      { value: "療術業" },
      { value: "医療に附帯するサービス業" },
      { value: "管理，補助的経済活動を行う事業所（84保健衛生）" },
      { value: "保健所" },
      { value: "健康相談施設" },
      { value: "その他の保健衛生" },
      { value: "管理，補助的経済活動を行う事業所（85社会保険・社会福祉・介護事業）" },
      { value: "社会保険事業団体" },
      { value: "福祉事務所" },
      { value: "児童福祉事業" },
      { value: "老人福祉・介護事業" },
      { value: "障害者福祉事業" },
      { value: "その他の社会保険・社会福祉・介護事業" }
    ]
  },
  {
    value: "公務（他に分類されるものを除く）",
    submenu: [
      { value: "立法機関" },
      { value: "司法機関" },
      { value: "行政機関" },
      { value: "都道府県機関" },
      { value: "市町村機関" }
    ]
  },
  {
    value: "情報通信業",
    submenu: [
      { value: "管理，補助的経済活動を行う事業所（37通信業）" },
      { value: "固定電気通信業" },
      { value: "移動電気通信業" },
      { value: "電気通信に附帯するサービス業" },
      { value: "管理，補助的経済活動を行う事業所（38放送業）" },
      { value: "公共放送業（有線放送業を除く）" },
      { value: "民間放送業（有線放送業を除く）" },
      { value: "有線放送業" },
      { value: "管理，補助的経済活動を行う事業所（39情報サービス業）" },
      { value: "ソフトウェア業" },
      { value: "情報処理・提供サービス業" },
      { value: "管理，補助的経済活動を行う事業所（40インターネット附随サービス業）" },
      { value: "インターネット附随サービス業" },
      { value: "管理，補助的経済活動を行う事業所（41映像・音声・文字情報制作業）" },
      { value: "映像情報制作・配給業" },
      { value: "音声情報制作業" },
      { value: "新聞業" },
      { value: "出版業" },
      { value: "広告制作業" },
      { value: "映像・音声・文字情報制作に附帯するサービス業" }
    ]
  },
  {
    value: "漁業",
    submenu: [
      { value: "管理，補助的経済活動を行う事業所（03漁業）" },
      { value: "海面漁業" },
      { value: "内水面漁業" },
      { value: "管理，補助的経済活動を行う事業所（04水産養殖業）" },
      { value: "海面養殖業" },
      { value: "内水面養殖業" }
    ]
  },
  {
    value: "分類不能の産業",
      submenu: [
      { value: "分類不能の産業" }
    ],
  },
  {
    value: "不動産業，物品賃貸業",
    submenu: [
      { value: "管理，補助的経済活動を行う事業所（68不動産取引業）" },
      { value: "建物売買業，土地売買業" },
      { value: "不動産代理業・仲介業" },
      { value: "管理，補助的経済活動を行う事業所（69不動産賃貸業・管理業）" },
      { value: "不動産賃貸業（貸家業，貸間業を除く）" },
      { value: "貸家業，貸間業" },
      { value: "駐車場業" },
      { value: "不動産管理業" },
      { value: "管理，補助的経済活動を行う事業所（70物品賃貸業）" },
      { value: "各種物品賃貸業" },
      { value: "産業用機械器具賃貸業" },
      { value: "事務用機械器具賃貸業" },
      { value: "自動車賃貸業" },
      { value: "スポーツ・娯楽用品賃貸業" },
      { value: "その他の物品賃貸業" }
    ]
  },
  {
    value: "農業，林業",
    submenu: [
      { value: "管理，補助的経済活動を行う事業所（01農業）" },
      { value: "耕種農業" },
      { value: "畜産農業" },
      { value: "農業サービス業（園芸サービス業を除く）" },
      { value: "園芸サービス業" },
      { value: "管理，補助的経済活動を行う事業所（02林業）" },
      { value: "育林業" },
      { value: "素材生産業" },
      { value: "特用林産物生産業（きのこ類の栽培を除く）" },
      { value: "林業サービス業" },
      { value: "その他の林業" }
    ]
  },
  {
    value: "金融業，保険業",
    submenu: [
      { value: "管理，補助的経済活動を行う事業所（62銀行業）" },
      { value: "中央銀行" },
      { value: "銀行（中央銀行を除く）" },
      { value: "管理，補助的経済活動を行う事業所（63協同組織金融業）" },
      { value: "中小企業等金融業" },
      { value: "農林水産金融業" },
      { value: "管理，補助的経済活動を行う事業所（64貸金業，クレジットカード業等非預金信用機関）" },
      { value: "貸金業" },
      { value: "質屋" },
      { value: "クレジットカード業，割賦金融業" },
      { value: "その他の非預金信用機関" },
      { value: "管理，補助的経済活動を行う事業所（65金融商品取引業，商品先物取引業）" },
      { value: "金融商品取引業" },
      { value: "商品先物取引業，商品投資顧問業" },
      { value: "管理，補助的経済活動を行う事業所（66補助的金融業等）" },
      { value: "補助的金融業，金融附帯業" },
      { value: "信託業" },
      { value: "金融代理業" },
      { value: "管理，補助的経済活動を行う事業所（67保険業）" },
      { value: "生命保険業" },
      { value: "損害保険業" },
      { value: "共済事業・少額短期保険業" },
      { value: "保険媒介代理業" },
      { value: "保険サービス業" }
    ]
  },
  {
    value: "卸売業，小売業",
    submenu: [
      { value: "管理，補助的経済活動を行う事業所（50各種商品卸売業）" },
      { value: "各種商品卸売業" },
      { value: "管理，補助的経済活動を行う事業所（51繊維・衣服等卸売業）" },
      { value: "繊維品卸売業（衣服，身の回り品を除く）" },
      { value: "衣服卸売業" },
      { value: "身の回り品卸売業" },
      { value: "管理，補助的経済活動を行う事業所（52飲食料品卸売業）" },
      { value: "農畜産物・水産物卸売業" },
      { value: "食料・飲料卸売業" },
      { value: "管理，補助的経済活動を行う事業所（53建築材料，鉱物・金属材料等卸売業）" },
      { value: "建築材料卸売業" },
      { value: "化学製品卸売業" },
      { value: "石油・鉱物卸売業" },
      { value: "鉄鋼製品卸売業" },
      { value: "非鉄金属卸売業" },
      { value: "再生資源卸売業" },
      { value: "管理，補助的経済活動を行う事業所（54機械器具卸売業）" },
      { value: "産業機械器具卸売業" },
      { value: "自動車卸売業" },
      { value: "電気機械器具卸売業" },
      { value: "その他の機械器具卸売業" },
      { value: "管理，補助的経済活動を行う事業所（55その他の卸売業）" },
      { value: "家具・建具・じゅう器等卸売業" },
      { value: "医薬品・化粧品等卸売業" },
      { value: "紙・紙製品卸売業" },
      { value: "他に分類されない卸売業" },
      { value: "管理，補助的経済活動を行う事業所（56各種商品小売業）" },
      { value: "百貨店，総合スーパー" },
      { value: "その他の各種商品小売業（従業者が常時50人未満のもの）" },
      { value: "管理，補助的経済活動を行う事業所（57織物・衣服・身の回り品小売業）" },
      { value: "呉服・服地・寝具小売業" },
      { value: "男子服小売業" },
      { value: "婦人・子供服小売業" },
      { value: "靴・履物小売業" },
      { value: "その他の織物・衣服・身の回り品小売業" },
      { value: "管理，補助的経済活動を行う事業所（58飲食料品小売業）" },
      { value: "各種食料品小売業" },
      { value: "野菜・果実小売業" },
      { value: "食肉小売業" },
      { value: "鮮魚小売業" },
      { value: "酒小売業" },
      { value: "菓子・パン小売業" },
      { value: "その他の飲食料品小売業" },
      { value: "管理，補助的経済活動を行う事業所（59機械器具小売業）" },
      { value: "自動車小売業" },
      { value: "自転車小売業" },
      { value: "機械器具小売業（自動車，自転車を除く）" },
      { value: "管理，補助的経済活動を行う事業所（60その他の小売業）" },
      { value: "家具・建具・畳小売業" },
      { value: "じゅう器小売業" },
      { value: "医薬品・化粧品小売業" },
      { value: "農耕用品小売業" },
      { value: "燃料小売業" },
      { value: "書籍・文房具小売業" },
      { value: "スポーツ用品・がん具・娯楽用品・楽器小売業" },
      { value: "写真機・時計・眼鏡小売業" },
      { value: "他に分類されない小売業" },
      { value: "管理，補助的経済活動を行う事業所（61無店舗小売業）" },
      { value: "通信販売・訪問販売小売業" },
      { value: "自動販売機による小売業" },
      { value: "その他の無店舗小売業" }
    ]
  },
  {
    value: "電気・ガス・熱供給・水道業",
    submenu: [
      { value: "管理，補助的経済活動を行う事業所（33電気業）" },
      { value: "電気業" },
      { value: "管理，補助的経済活動を行う事業所（34ガス業）" },
      { value: "ガス業" },
      { value: "管理，補助的経済活動を行う事業所（35熱供給業）" },
      { value: "熱供給業" },
      { value: "管理，補助的経済活動を行う事業所（36水道業）" },
      { value: "上水道業" },
      { value: "工業用水道業" },
      { value: "下水道業" }
    ]
  },
  {
    value: "宿泊業，飲食サービス業",
    submenu: [
      { value: "管理，補助的経済活動を行う事業所（75宿泊業）" },
      { value: "旅館，ホテル" },
      { value: "簡易宿所" },
      { value: "下宿業" },
      { value: "その他の宿泊業" },
      { value: "管理，補助的経済活動を行う事業所（76飲食店）" },
      { value: "食堂，レストラン（専門料理店を除く）" },
      { value: "専門料理店" },
      { value: "そば・うどん店" },
      { value: "すし店" },
      { value: "酒場，ビヤホール" },
      { value: "バー，キャバレー，ナイトクラブ" },
      { value: "喫茶店" },
      { value: "その他の飲食店" },
      { value: "管理，補助的経済活動を行う事業所（77持ち帰り・配達飲食サービス業）" },
      { value: "持ち帰り飲食サービス業" },
      { value: "配達飲食サービス業" }
    ]
  },
  {
    value: "運輸業，郵便業",
    submenu: [
      { value: "管理，補助的経済活動を行う事業所（42鉄道業）" },
      { value: "鉄道業" },
      { value: "管理，補助的経済活動を行う事業所（43道路旅客運送業）" },
      { value: "一般乗合旅客自動車運送業" },
      { value: "一般乗用旅客自動車運送業" },
      { value: "一般貸切旅客自動車運送業" },
      { value: "その他の道路旅客運送業" },
      { value: "管理，補助的経済活動を行う事業所（44道路貨物運送業）" },
      { value: "一般貨物自動車運送業" },
      { value: "特定貨物自動車運送業" },
      { value: "貨物軽自動車運送業" },
      { value: "集配利用運送業" },
      { value: "その他の道路貨物運送業" },
      { value: "管理，補助的経済活動を行う事業所（45水運業）" },
      { value: "外航海運業" },
      { value: "沿海海運業" },
      { value: "内陸水運業" },
      { value: "船舶貸渡業" },
      { value: "管理，補助的経済活動を行う事業所（46航空運輸業）" },
      { value: "航空運送業" },
      { value: "航空機使用業（航空運送業を除く）" },
      { value: "管理，補助的経済活動を行う事業所（47倉庫業）" },
      { value: "倉庫業（冷蔵倉庫業を除く）" },
      { value: "冷蔵倉庫業" },
      { value: "管理，補助的経済活動を行う事業所（48運輸に附帯するサービス業）" },
      { value: "港湾運送業" },
      { value: "貨物運送取扱業（集配利用運送業を除く）" },
      { value: "運送代理店" },
      { value: "こん包業" },
      { value: "運輸施設提供業" },
      { value: "その他の運輸に附帯するサービス業" },
      { value: "管理，補助的経済活動を行う事業所（49郵便業）" },
      { value: "郵便業（信書便事業を含む）" }
    ]
  },
  {
    value: "生活関連サービス業，娯楽業",
      submenu: [
      { value: "管理，補助的経済活動を行う事業所（78洗濯・理容・美容・浴場業）" },
      { value: "洗濯業" },
      { value: "理容業" },
      { value: "美容業" },
      { value: "一般公衆浴場業" },
      { value: "その他の公衆浴場業" },
      { value: "その他の洗濯・理容・美容・浴場業" },
      { value: "管理，補助的経済活動を行う事業所（79その他の生活関連サービス業）" },
      { value: "旅行業" },
      { value: "家事サービス業" },
      { value: "衣服裁縫修理業" },
      { value: "物品預り業" },
      { value: "火葬・墓地管理業" },
      { value: "冠婚葬祭業" },
      { value: "他に分類されない生活関連サービス業" },
      { value: "管理，補助的経済活動を行う事業所（80娯楽業）" },
      { value: "映画館" },
      { value: "興行場（別掲を除く），興行団" },
      { value: "競輪・競馬等の競走場，競技団" },
      { value: "スポーツ施設提供業" },
      { value: "公園，遊園地" },
      { value: "遊戯場" },
      { value: "その他の娯楽業" }
    ]
  },
  {
    value: "教育，学習支援業",
    submenu: [
      { value: "管理，補助的経済活動を行う事業所（81学校教育）" },
      { value: "幼稚園" },
      { value: "小学校" },
      { value: "中学校" },
      { value: "高等学校，中等教育学校" },
      { value: "特別支援学校" },
      { value: "高等教育機関" },
      { value: "専修学校，各種学校" },
      { value: "学校教育支援機関" },
      { value: "幼保連携型認定こども園" },
      { value: "管理，補助的経済活動を行う事業所（82その他の教育，学習支援業）" },
      { value: "社会教育" },
      { value: "職業・教育支援施設" },
      { value: "学習塾" },
      { value: "教養・技能教授業" },
      { value: "他に分類されない教育，学習支援業" }
    ]
  }
]

export const KEYMAN_RANK_TYPE = {
  LETTER: "letter",
  REACTION: "reaction",
  SHOUDAN: "shoudan",
}

export const DUMMY_RANK_STATS = {
  letter_rank: "-",
  letter_percentage: 0,
  reaction_rank: "-",
  reaction_percentage: 0,
  shoudan_rank: "-",
  shoudan_percentage: 0,
  denominator: "",
}

// 上場市場
export const LISTING_TYPE = [
  {name: '未上場', value: 'unlisted'},
  {name: '上場廃止', value: 'delisted'},
  {name: '東証プライム', value: 'prime'},
  {name: '東証スタンダード', value: 'standard'},
  {name: '東証グロース', value: 'growth'},
  {name: 'TOKYO PRO Market', value: 'TOKYO_PRO_Market'},
  {name: '札幌', value: 'SSE'},
  {name: '福岡', value: 'FSE'},
  {name: 'アンビシャス', value: 'ambitious'},
  {name: '名証プレミア', value: 'premier'},
  {name: '名証メイン', value: 'main'},
  {name: '名証ネクスト', value: 'next'},
  {name: 'Ｑ-Ｂｏａｒｄ', value: 'Q_Board'},
]

// 企業関連ページ情報
export const CORPORATION_RELEVANT_INFO = [
  { "name": "本社の近い企業", "value": "relevant_main_office" },
  { "name": "従業員数の近い企業", "value": "relevant_number_of_employees" },
  { "name": "設立日の近い企業", "value": "relevant_establishment_year" },
  { "name": "資本金の近い企業", "value": "relevant_capital_stock" },
  { "name": "売上高の近い企業", "value": "relevant_amount_of_sales" },
  { "name": "平均年齢の近い企業", "value": "relevant_average_age" },
  { "name": "平均年収の近い企業", "value": "relevant_annual_income" },
  { "name": "決算月の同じ企業", "value": "relevant_clothing_month" },
  { "name": "他の上場企業", "value": "relevant_listing_type" }
]

export const PREFECTURES = [
  {value: '北海道', name: 'hokkaido',submenu: [
    {value: '北海道', name: 'hokkaido'},
  ]},
  {value: '東北', name: 'tohoku', submenu: [
    {value: '青森県', name: 'aomori'},
    {value: '岩手県', name: 'iwate'},
    {value: '宮城県', name: 'miyagi'},
    {value: '秋田県', name: 'akita'},
    {value: '山形県', name: 'yamagata'},
    {value: '福島県', name: 'fukushima'},
  ]},
  {value: '関東', name: 'kanto', submenu: [
    {value: '東京都', name: 'tokyo'},
    {value: '神奈川県', name: 'kanagawa'},
    {value: '茨城県', name: 'ibaraki'},
    {value: '栃木県', name: 'tochigi'},
    {value: '群馬県', name: 'gunma'},
    {value: '埼玉県', name: 'saitama'},
    {value: '千葉県', name: 'chiba'},
  ]},
  {value: '甲信越・北陸', name: 'hokuriku', submenu: [
    {value: '新潟県', name: 'niigata'},
    {value: '富山県', name: 'toyama'},
    {value: '石川県', name: 'ishikawa'},
    {value: '福井県', name: 'fukui'},
    {value: '山梨県', name: 'yamanashi'},
    {value: '長野県', name: 'nagano'},
  ]},
  {value: '東海', name: 'tokai', submenu: [
    {value: '愛知県', name: 'aichi'},
    {value: '岐阜県', name: 'gifu'},
    {value: '三重県', name: 'mie'},
    {value: '静岡県', name: 'shizuoka'},
  ]},
  {value: '関西', name: 'kansai', submenu: [
    {value: '滋賀県', name: 'shiga'},
    {value: '奈良県', name: 'nara'},
    {value: '京都府', name: 'kyoto'},
    {value: '大阪府', name: 'osaka'},
    {value: '兵庫県', name: 'hyogo'},
    {value: '和歌山県', name: 'wakayama'},
  ]},
  {value: '中国', name: 'chugoku', submenu: [
    {value: '岡山県', name: 'okayama'},
    {value: '広島県', name: 'hiroshima'},
    {value: '鳥取県', name: 'tottori'},
    {value: '島根県', name: 'shimame'},
    {value: '山口県', name: 'yamaguchi'},
  ]},
  {value: '四国', name: 'shikoku', submenu: [
    {value: '香川県', name: 'kagawa'},
    {value: '徳島県', name: 'tokushima'},
    {value: '愛媛県', name: 'ehime'},
    {value: '高知県', name: 'kochi'},
  ]},
  {value: '九州・沖縄', name: 'kyusyu', submenu: [
    {value: '福岡県', name: 'fukuoka'},
    {value: '佐賀県', name: 'saga'},
    {value: '長崎県', name: 'nagasaki'},
    {value: '大分県', name: 'oita'},
    {value: '熊本県', name: 'kumamoto'},
    {value: '宮崎県', name: 'miyagi'},
    {value: '鹿児島県', name: 'kagoshima'},
    {value: '沖縄県', name: 'okinawa'},
  ]},
]
